<template>
  <div>
    <div class="p-5">
      <v-form ref="form" v-model="form" class="p-lg-3 row" lazy-validation>
        <div class="col-lg-6 form-group clearfix mb-3">
          <label>Nome Fantasia</label>
          <v-text-field
            :error-messages="error.nome_fantasia"
            v-model="nomeFantasia"
            :rules="[rules.required]"
            :type="'text'"
            placeholder="Nome fantasia"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-6 form-group clearfix mb-3">
          <label>Razão social</label>
          <v-text-field
            :error-messages="error.razao_social"
            v-model="razaoSocial"
            :rules="[rules.required]"
            :type="'text'"
            placeholder="Razão social"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>CNPJ</label>
          <v-text-field
            v-mask="'##.###.###/####-##'"
            :error-messages="error.cnpj"
            :rules="[rules.required, services.validationService.isCNPJ()]"
            v-model="cnpj"
            :type="'text'"
            placeholder="00.000.000/0000-00"
            style="padding-top: 0 !important"
          ></v-text-field>
          <span class="text-muted">ex: "12.345.678/0001-90"</span>
        </div>
        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Telefone Celular:</label>
          <v-text-field
            v-mask="'(##)#####-####'"
            :error-messages="error.telefone_1"
            :rules="[rules.required]"
            v-model="telefone1"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Telefone Fixo:</label>
          <v-text-field
            v-mask="'(##)####-####'"
            :error-messages="error.telefone_2"
            v-model="telefone2"
            :type="'text'"
            placeholder="(99) 99999-9999"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>CEP</label>

          <v-text-field
            v-mask="'#####-###'"
            :error-messages="error.cep"
            :rules="[rules.required]"
            v-model="cep"
            @blur="searchCep"
            :type="'text'"
            placeholder="00000-000"
            style="padding-top: 0 !important"
          ></v-text-field>

          <span class="text-muted">ex: "xxxxx-xxx"</span>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Estado</label>
          <v-text-field
            :error-messages="error.estado"
            v-model="estado"
            :rules="[rules.required]"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Cidade</label>

          <v-text-field
            :error-messages="error.cidade"
            v-model="cidade"
            :rules="[rules.required]"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Bairro</label>

          <v-text-field
            :error-messages="error.bairro"
            v-model="bairro"
            :rules="[rules.required]"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Logradouro</label>

          <v-text-field
            :error-messages="error.logradouro"
            v-model="logradouro"
            :rules="[rules.required]"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Número</label>

          <v-text-field
            :error-messages="error.numero"
            v-model="numero"
            :rules="[rules.required]"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Complemento</label>

          <v-text-field
            :error-messages="error.complemento"
            v-model="complemento"
            :rules="[rules.required]"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label>Referência</label>

          <v-text-field
            :error-messages="error.referencias"
            v-model="referencias"
            :type="'text'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrarUnidade"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </v-form>
    </div>
  </div>
</template>

<script>
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import UnidadeService from "../../services/unidade_service";
import AuthService from "../../services/auth_service";
import ValidationService from "../../services/validation_service";

export default {
  mixins: [CEPMixin, Mixin],
  data() {
    return {
      form: false,
      loading: false,
      nomeFantasia: "",
      razaoSocial: "",
      cnpj: "",
      telefone1: "",
      telefone2: "",
      cep: "",
      estado: "",
      cidade: "",
      bairro: "",
      logradouro: "",
      numero: "",
      complemento: "",
      referencias: "",
      error: {
        nome_fantasia: "",
        razao_social: "",
        cnpj: "",
        telefone_1: "",
        telefone_2: "",
        cep: "",
        estado: "",
        cidade: "",
        bairro: "",
        logradouro: "",
        numero: "",
        complemento: "",
        referencias: "",
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
      },
      services: {
        unidadeService: UnidadeService.build(),
        authService: AuthService.build(),
        validationService: ValidationService,
      },
    };
  },
  mounted() {
    this.formCadastro = document.forms[0];
  },
  methods: {
    searchCep() {
      this.$buscarCep(this, this.cep);
    },
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },
    async cadastrarUnidade() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const unidade = {
        nome_fantasia: this.nomeFantasia,
        razao_social: this.razaoSocial,
        cnpj: this.cnpj,
        telefone_1: this.telefone1,
        telefone_2: this.telefone2,
        cep: this.cep,
        estado: this.estado,
        cidade: this.cidade,
        bairro: this.bairro,
        logradouro: this.logradouro,
        numero: this.numero,
        complemento: this.complemento,
        referencias: this.referencias,
      };
      this.loading = true;
      this.services.unidadeService
        .cadastrarUnidade(unidade)
        .then(async (response) => {
          const body = await response.json();
          const message =
            body.message || this.$global.messages.internalServerError;
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({ message, color: "error" });
            const errors = body.errors;
            this.zerarErros();
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
            return;
          }
          this.zerarErros();
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: "sucess",
          });
          this.zerarFormulario();
          this.$emit("response");
          this.$emit("close");
        })
        .catch((e) => {
          console.log({ e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
