<template>
  <v-card>
    <v-toolbar class="modal-nav-ajustes"
      ><v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Requisições</v-toolbar-title>
    </v-toolbar>
    <template>
      <v-tabs class="container-formata-tabs">
        <v-tab>Requisitar Exames</v-tab>
        <v-tab-item>
          <RequisitarExames
            :petId="infoPaciente.PetCodigo"
            :tutorId="infoPaciente.TutorCodigo"
          />
        </v-tab-item>

        <v-tab>Requisitar Internação</v-tab>
        <v-tab-item>
          <RequisitarInternacao
            :petId="infoPaciente.PetCodigo"
            :tutorId="infoPaciente.TutorCodigo"
          />
        </v-tab-item>

        <v-tab>Requisitar Medicação</v-tab>
        <v-tab-item>
          <RequisitarMedicacao
            :petId="infoPaciente.PetCodigo"
            :tutorId="infoPaciente.TutorCodigo"
          />
        </v-tab-item>

        <v-tab>Requisitar Cirurgia</v-tab>
        <v-tab-item>
          <Requisitar-Cirurgia
            :petId="infoPaciente.PetCodigo"
            :tutorId="infoPaciente.TutorCodigo"
          />
        </v-tab-item>

        <v-tab>Requisitar Consulta</v-tab>
        <v-tab-item>
          <Requisitar-Consulta
            :tutorId="tutorRequisicao"
            :petId="petRequisicao"
          />
        </v-tab-item>
      </v-tabs>
    </template>

    <v-card-actions class="justify-end">
      <v-btn text @click="$emit('close')">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import PetsService from "@/services/pets_service.js";
import RequisitarExames from "@/components/RequisitarExames/index.vue";
import PacienteService from "@/services/paciente_service.js";
import RequisitarInternacao from "@/components/RequisitarInternacao/index.vue";
import RequisitarMedicacao from "@/components/RequisitarMedicacao/index.vue";
import RequisitarCirurgia from "@/components/RequisitarCirurgia/index.vue";
import RequisitarConsulta from "@/components/RequisitarConsulta/index.vue";

export default {
  components: {
    RequisitarExames,
    RequisitarInternacao,
    RequisitarMedicacao,
    RequisitarCirurgia,
    RequisitarConsulta,
  },
  data() {
    return {
      dialogErrorProtuarios: false,
      dialogObservacoes: false,
      infoPaciente: {},
      loadingSearch: false,
      tutores: [],
      pets: [],
      prontuarios: [],
      dialogSelectProntuarios: false,
      dialogProntuarios: false,
      selectedTutorProntuarios: "",
      selectedPetProntuarios: "",
      searchTutorProntuarios: "",
      petService: PetsService.build(),
      requisicoes: ["Exame", "Medicação"],
      dialogSelectRequisicao: false,
      dialogRequisitarExame: false,
      dialogRequisitarMedicacao: false,
      dialogComandas: false,
      selectedRequisicao: "",
      perfil: "",
      dialogRequisitarCirurgia: false,
      dialogRequisitarConsulta: false,
      dialogRequisitarInternacao: false,
      dialogRequisicao: false,
      tutor: {},
      petIdComanda: null,
      tutorRequisicao: {},
      petRequisicao: {},
    };
  },
  props: {
    petId: {
      required: true,
    },
    tutorId: {
      required: true,
    },
    consultaId: {
      required: true,
    },
  },
  watch: {
    searchTutorProntuarios(nome) {
      if (!nome) {
        return;
      }
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutores = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutores = body.data.pacientes;
            } else {
              this.tutores = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutores = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    selectedTutorProntuarios() {
      this.pets = "";
      this.pets = this.selectedTutorProntuarios.pets;
    },
  },
  beforeMount() {
    this.infoPaciente = JSON.parse(localStorage.getItem("infoPaciente"));
    console.log(this.infoPaciente);
    this.perfil = JSON.parse(sessionStorage.vuex).perfil.id;
    this.carregarPet();
    console.log(this.tutorRequisicao);
  },
  methods: {
    carregarPet() {
      this.tutorRequisicao = {
        nome: this.infoPaciente.NomeTutor,
        id_cliente: this.infoPaciente.TutorCodigo,
      };
      this.petRequisicao = {
        nome: this.infoPaciente.NomePet,
        id_pet: this.infoPaciente.PetCodigo,
      };
    },
    async listProntuarios() {
      await this.petService
        .getConsultasbyUnidade(
          this.infoPaciente.PetCodigo,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        // .getConsultas(this.infoPaciente.PetCodigo)
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            // this.$_ACTIONS_showSnackbarMessage({
            //   message: data.message,
            //   color: "error",
            // });
            this.dialogErrorProtuarios = true;
            return;
          }
          console.log(data);
          this.prontuarios = data.data.consultas;
          this.dialogProntuarios = true;
          this.dialogSelectProntuarios = false;
        });
    },
    requisitar() {
      this.dialogSelectRequisicao = false;

      if (this.selectedRequisicao == "Exame") {
        this.dialogRequisitarExame = true;
      } else if (this.selectedRequisicao == "Medicação") {
        this.dialogRequisitarMedicacao = true;
      }
    },
    // dialogSelectPront() {
    //   this.selectedPetProntuarios = "";
    //   this.selectedTutorProntuarios = "";
    //   this.searchTutorProntuarios = "";
    //   this.dialogSelectProntuarios = true;
    // },
  },
};
</script>
<style>
.container-formata-tabs {
  width: 100%;
}
.modal-nav-ajustes {
  background-color: #1daf80 !important;
  color: #ffffff !important;
  font-size: 1.5rem;
  font-weight: 500;
}
</style>
