<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline" v-if="visualizar"
            >Visualizar administrativo</span
          >
          <span class="headline" v-else>Editar administrativo</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form class="p-lg-3 row" ref="form">
            <div class="col-lg-6 form-group clearfix mb-3">
              <label for="nome">Nome Completo</label>
              <v-text-field
                :error-messages="error.nome"
                v-model="administrativo.nome"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Nome é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                :type="'text'"
                placeholder="Seu nome completo"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-6 form-group clearfix mb-3">
              <label for="emailCadastroUsuario">Email</label>
              <v-text-field
                :error-messages="error.email"
                v-model="administrativo.email"
                :disabled="visualizar"
                background-color="grey lighten-2"
                readonly
                :rules="[
                  validationService.required('Campo Email é obrigatório.'),
                  validationService.isEmail('Email inválido.'),
                ]"
                :type="'email'"
                placeholder="Escreva seu email"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone01">Telefone Celular:</label>
              <v-text-field
                v-mask="'(##)#####-####'"
                :error-messages="error.telefone1"
                v-model="administrativo.telefone1"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Telefone é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                placeholder="(99) 99999-9999"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone01">Telefone Fixo:</label>
              <v-text-field
                v-mask="'(##)####-####'"
                :error-messages="error.telefone2"
                v-model="administrativo.telefone2"
                :disabled="visualizar"
                :type="'text'"
                placeholder="(99) 99999-9999"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CPF">CPF</label>
              <v-text-field
                v-mask="'###.###.###-##'"
                :error-messages="error.cpf"
                v-model="administrativo.cpf"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo CPF é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                placeholder="000.000.000-00"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <span class="text-muted">ex: "123.456.7890-12"</span>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="RG">RG</label>
              <v-text-field
                :error-messages="error.rg"
                v-model="administrativo.rg"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <!-- <span class="text-muted">ex: "xx.xxx.xxx-x"</span> -->
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label>Data de nascimento</label>
              <v-text-field
                :error-messages="error.data_nascimento"
                v-model="administrativo.dataNascimento"
                :disabled="visualizar"
                clear-icon="clear"
                :rules="[
                  validationService.required(
                    'Campo Data de nascimento é obrigatório.'
                  ),
                  validationService.isNotEmpty(),
                ]"
                style="margin: 0 5px; padding-top: 5px !important"
                :type="'date'"
                :outlined="false"
              />
              <span class="text-muted">ex: "Dia/Mês/Ano"</span>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label class="">Gênero</label>
              <v-radio-group
                :error-messages="error.genero"
                v-model="administrativo.genero"
                :rules="[
                  validationService.required('Campo Gênero é obrigatório.'),
                ]"
                :disabled="visualizar"
              >
                <v-radio label="Masculino" value="M" />
                <v-radio label="Feminino" value="F" />
                <v-radio label="Outros" value="O" />
              </v-radio-group>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Estado-civil">Estado civil</label>
              <v-text-field
                :error-messages="error.estadoCivil"
                v-model="administrativo.estadoCivil"
                clear-icon="clear"
                style="margin: 0 5px; padding-top: 5px !important"
                :type="'text'"
                :outlined="false"
                :disabled="visualizar"
              />
              <span class="text-muted"
                >ex: Casado, Solteiro, Divorciado, Viúvo"</span
              >
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CEP">CEP</label>
              <v-text-field
                v-mask="'#####-###'"
                :error-messages="error.cep"
                v-model="administrativo.cep"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo CEP é obrigatório.'),
                ]"
                @blur="searchCep"
                placeholder="00000-000"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <span class="text-muted">ex: "xxxxx-xxx"</span>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Rua">Logradouro</label>
              <v-text-field
                :error-messages="error.logradouro"
                v-model="administrativo.logradouro"
                :disabled="visualizar"
                background-color="grey lighten-2"
                :rules="[
                  validationService.required('Campo Logradouro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Bairro">Bairro</label>
              <v-text-field
                :error-messages="error.bairro"
                v-model="administrativo.bairro"
                :disabled="visualizar"
                background-color="grey lighten-2"
                :rules="[
                  validationService.required('Campo Bairro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Numero">Número</label>
              <v-text-field
                :error-messages="error.numero"
                v-model="administrativo.numero"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Número é obrigatório.'),
                ]"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Complemento">Complemento</label>
              <v-text-field
                :error-messages="error.complemento"
                v-model="administrativo.complemento"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-6 form-group clearfix mb-3">
              <label for="Referencia">Referência</label>
              <v-text-field
                :error-messages="error.referencias"
                v-model="administrativo.referencias"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Cidade">Cidade</label>
              <v-text-field
                :error-messages="error.cidade"
                v-model="administrativo.cidade"
                background-color="grey lighten-2"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Cidade é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Estado">Estado</label>
              <v-text-field
                :error-messages="error.estado"
                v-model="administrativo.estado"
                background-color="grey lighten-2"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Estado é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-4 form-group clearfix mb-3">
              <label>Perfil</label>
              <v-select
                outlined
                :items="perfisItems"
                v-model="administrativo.idPerfil"
                :disabled="visualizar"
              />
            </div>

            <div class="col-lg-4 form-group clearfix mb-3">
              <label>Unidades</label>
              <v-select
                outlined
                :items="unidadesItems"
                v-model="administrativo.idClinica"
                :disabled="visualizar"
                multiple
              />
            </div>
            <div class="col-lg-4 form-group clearfix mb-3">
              <label>Status</label>
              <v-select
                outlined
                :items="statusSelect"
                item-text="descricao"
                item-value="id"
                v-model="ativo"
                :disabled="visualizar"
              />
            </div>

            <div class="col-12 form-group">
              <button
                v-if="!visualizar"
                class="btn btn-app-primary btn-flex btn-rounded font-weight-bold justify-content-end"
                @click.prevent="atualizarAdministrativo"
              >
                <span v-if="!loading"> Salvar </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </button>
            </div>
          </v-form>
          <v-snackbar
            v-if="messageSnackbar"
            v-model="messageSnackbar"
            :color="typeMessageSnackbar"
            :timeout="-1"
          >
            {{ messageSnackbar }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                :color="'white'"
                v-bind="attrs"
                @click="fecharSnackbar"
              >
                Ok
              </v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AdministrativoService from "@/services/administrativo_service.js";
import ValidationService from "@/services/validation_service.js";
import CEPMixin from "@/mixins/cep_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import { mapActions } from "vuex";
import PerfilService from "../../services/perfil_service";
import UnidadeService from "../../services/unidade_service";

export default {
  mixins: [CEPMixin, DadosCadastraisMixin],
  props: {
    administrativo: Object,
    visualizar: Boolean,
  },
  beforeMount() {
    this.validationService = ValidationService;
  },
  data() {
    return {
      tipoFoto: "",
      addFoto: true,
      preview: "",
      foto: null,
      avatarAdministrativo: "",
      messageSnackbar: "",
      typeMessageSnackbar: "",
      show1: false,
      show2: false,
      loading: false,
      password: "",
      // Formulário
      formCadastro: "",
      nome: "",
      email: "",
      telefone1: "",
      telefone2: "",
      cpf: "",
      rg: "",
      dataNascimento: "",
      genero: "",
      estadoCivil: "",
      observacoes: "",
      cep: "",
      logradouro: "",
      bairro: "",
      numero: "",
      complemento: "",
      referencias: "",
      cidade: "",
      estado: "",
      convenio: "",
      senha: "",
      perfilId: 0,
      unidadeId: 0,
      error: {
        nome: "",
        email: "",
        telefone1: "",
        telefone2: "",
        cpf: "",
        rg: "",
        data_nascimento: "",
        genero: "",
        estadoCivil: "",
        observacoes: "",
        cep: "",
        logradouro: "",
        bairro: "",
        numero: "",
        complemento: "",
        referencias: "",
        cidade: "",
        estado: "",
        senha: "",
        statusResposta: null,
        validationService: {},
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
        emailMatch: () => `O e-mail e a senha inseridos não correspondem`,
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email inválido.";
        },
      },
      services: {
        perfilService: PerfilService.build(),
        unidadeService: UnidadeService.build(),
        administrativoService: AdministrativoService.build(),
      },
      loadingData: false,
      perfis: [],
      unidades: [],
      statusSelect: [
        { descricao: "Ativo", id: 1 },
        { descricao: "Inativo", id: 2 },
      ],
      ativo: 0,
    };
  },
  async mounted() {
    this.formCadastro = document.forms[0];
    this.CEP_MIXIN_cep = this.administrativo.cep;
    const [perfis, unidades] = await Promise.all([
      this.services.perfilService.getPerfilList(),
      this.services.unidadeService.getUnidadeList(),
    ]);
    this.loadingData = false;
    this.perfis = perfis;
    this.unidades = unidades;
    this.ativo = this.administrativo.ativo ? 1 : 2;
  },
  computed: {
    perfisItems() {
      return this.perfis.map((perfil) => ({
        text: perfil.descricao,
        value: ~~perfil.perfilId,
      }));
    },
    unidadesItems() {
      return this.unidades.map((u) => ({
        text: u.nomeFantasia,
        value: parseInt(u.unidadeId),
      }));
    },
    image_perfil() {
      var src = "";
      let tipo = this.administrativo.tipo;
      if (!tipo) {
        return "";
      }
      tipo = this.administrativo.tipo.toLowerCase();
      switch (tipo) {
        case "png":
          src = `data:image/png;base64,${this.administrativo.foto}`;
          break;
        case "jpeg":
          src = `data:image/jpeg;base64,${this.administrativo.foto}`;
          break;
        case "jpg":
          src = `data:image/jpeg;base64,${this.administrativo.foto}`;
          break;
        default:
          src = "https://picsum.photos/id/103/200/300";
          break;
      }

      return src;
    },
  },
  watch: {
    administrativo() {
      this.CEP_MIXIN_logradouro = this.administrativo.logradouro;
      this.CEP_MIXIN_bairro = this.administrativo.bairro;
      this.CEP_MIXIN_cidade = this.administrativo.cidade;
      this.CEP_MIXIN_estado = this.administrativo.estado;
      this.CEP_MIXIN_cep = this.administrativo.cep;
    },
  },

  methods: {
    searchCep() {
      this.$buscarCep(this.administrativo, this.administrativo.cep);
    },
    ...mapActions({
      $_ACTIONS_showSnackbarMessage: "showSnackbarMessage",
    }),
    fecharSnackbar() {
      this.messageSnackbar = "";
      this.zerarErros();
    },
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },

    clean() {
      this.addFoto = true;
    },
    onChange() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.preview = e.target.result;
      };
      if (file) {
        this.tipoFoto = file.type;
        reader.readAsDataURL(file);
      }
    },

    async atualizarAdministrativo() {
      if (!this.$refs.form.validate()) {
        return;
      }
      // if (!this.formCadastro.checkValidity()) {
      //   return;
      // }
      this.loading = !!1;
      const response = await this.services.administrativoService.atualizarAdministrativo(
        this.administrativo.administrativoId,
        {
          ativo: this.ativo,
          nome: this.administrativo.nome,
          email: this.administrativo.email,
          rg: this.administrativo.rg,
          genero: this.administrativo.genero,
          data_nascimento: this.administrativo.dataNascimento,
          profissao: this.administrativo.profissao,
          estado_civil: this.administrativo.estadoCivil,
          cep: this.administrativo.cep,
          numero: this.administrativo.numero,
          complemento: this.administrativo.complemento,
          referencias: this.administrativo.referencias,
          cpf: this.administrativo.cpf,
          telefone_1: this.administrativo.telefone1,
          telefone_2: this.administrativo.telefone2,
          id_perfil: this.administrativo.idPerfil,
          bairro: this.administrativo.bairro,
          cidade: this.administrativo.cidade,
          estado: this.administrativo.estado,
          logradouro: this.administrativo.logradouro,
          senha: this.administrativo.senha,
          id_clinica: this.administrativo.idClinica.join(),
        }
      );
      console.log({ administrativoId: response });
      this.loading = !!0;
      if (response === true) {
        this.zerarFormulario();
        this.$_ACTIONS_showSnackbarMessage({
          message: "O usuário foi atualizado com sucesso.",
          color: "sucess",
        });
        this.$emit("response");
        this.$emit("close");
      } else if (~~response === 0 && typeof response === "object") {
        const errors = response;
        Object.keys(errors).forEach((error) => {
          if (errors[error]) {
            this.error[error] = errors[error];
            console.log(errors[error]);
            console.log(this.error);
          }
        });
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Ocorreu um erro.",
          color: "error",
        });
      }
      // var formdata = new FormData();
      // const foto = document.querySelector("input[type=file]").files[0];
      //
      // formdata.append("nome", this.administrativo.nome);
      // formdata.append("email", this.administrativo.email);
      // formdata.append("telefone_1", this.administrativo.telefone_1);
      // formdata.append("telefone_2", this.administrativo.telefone_2);
      // formdata.append("cpf", this.administrativo.cpf);
      // formdata.append("rg", this.administrativo.rg);
      // formdata.append("data_nascimento", this.administrativo.data_nascimento);
      // formdata.append("genero", this.administrativo.genero);
      // formdata.append("estado_civil", this.administrativo.estado_civil);
      // formdata.append("profissao", this.administrativo.profissao);
      // formdata.append("cep", this.administrativo.cep);
      // formdata.append("logradouro", this.administrativo.logradouro);
      // formdata.append("bairro", this.administrativo.bairro);
      // formdata.append("cidade", this.administrativo.cidade);
      // formdata.append("estado", this.administrativo.estado);
      // formdata.append("numero", this.administrativo.numero);
      // formdata.append("complemento", this.administrativo.complemento);
      // formdata.append("referencias", this.administrativo.referencias);
      // formdata.append("convenio", this.administrativo.convenio);
      // formdata.append("id_medico", 1); //retirar
      // formdata.append("foto", foto);
      // this.loading = true;
      // const administrativoService = new AdministrativoService();
      // const idAdministrativoslterada = this.administrativo.id;
      // const idAdministrativoUsuario = this.$_GETTERS_usuario.idAdministrativo;
      // const responseFunctions = {
      //   onSucess: (status) => (body) => {
      //     this.$_ACTIONS_showSnackbarMessage({
      //       message: body.message || this.$global.messages.internalServerError,
      //       color: status === 200 ? "sucess" : "error",
      //     });
      //
      //     if (status === 200 && idAdministrativoslterada === idAdministrativoUsuario) {
      //       const usuarioDados = this.$_GETTERS_usuario;
      //       usuarioDados.dados.foto = this.preview;
      //       usuarioDados.dados.tipo = this.tipoFoto;
      //       this.$_ACTIONS_setUsuario(usuarioDados);
      //     }
      //   },
      //   onError: (error) => {
      //     this.messageSnackbar = error;
      //     this.typeMessageSnackbar = "error";
      //     console.log(error);
      //   },
      //   onEnd: () => {
      //     this.loading = false;
      //     this.administrativo = {};
      //     this.$emit("close");
      //   },
      // };
      // administrativoService.update(responseFunctions, formdata, this.administrativo.id);
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
