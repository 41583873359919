<template>
  <v-card>
    <v-toolbar style="background-color: #3e682a; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Atender </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-3">
      <h3>Requisições em aberto</h3>
      <v-data-table
        v-on:update:sort-by="options.ordenacao = true"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
        :headers="headersRequisicoes"
        :items="requisicoes"
        class="elevation-1"
        :loading="loadingRequisicoes"
        :loading-text="'Carregando...'"
      >
        <template v-slot:no-data>
          <p class="text-center">Nenhum registro encontrado</p>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            v-if="item.formularioId == 0 || item.filledAnamnese != 0"
            :loading="loadingRequisicaoId == item.requisicaoId"
            @click="marcarExecutado(item)"
          >
            Marcar executado
          </v-btn>
          <v-btn
            v-if="item.formularioId != 0 && item.filledAnamnese == 0"
            v-bind="attrs"
            @click="item.showModal = true"
          >
            Anamnese
          </v-btn>
          <v-dialog
            persistent
            fullscreen
            v-model="item.showModal"
            max-width="500px"
          >
            <modal-anamnese-exame
              @close="item.showModal = false"
              @carregarRequisicoes="carregarRequisicoes"
              @finalizarConsulta="marcarExecutado(item)"
              :formularioId="item.formularioId"
              :requisicaoId="item.requisicaoId"
              :paciente="{ nome: 'Paciente' }"
              v-if="item.showModal"
            />
          </v-dialog>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";
import ModalAnamneseExame from "../requisitar_exames/modal_anamnese_exame.vue";

export default {
  mixins: [Mixin],
  components: { ModalAnamneseExame },
  data: () => ({
    loadingRequisicoes: false,
    dialogAnmanese: false,
    headersRequisicoes: [
      {
        text: "Procedimento",
        align: "start",
        sortable: true,
        value: "procedimentoDescricao",
      },
      {
        text: "Convênio",
        align: "start",
        sortable: true,
        value: "convenioDescricao",
      },
      {
        text: "Tipo Convênio",
        align: "start",
        sortable: true,
        value: "tipoConvenio",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    requisicoes: [],
    requisicaoExameService: RequisicaoExameService.build(),
    loadingRequisicaoId: 0,
    showAnamneseModal: false,
  }),
  props: {
    petCodigo: {
      type: Number,
      required: true,
    },
    unidadeId: {
      type: Number,
      required: true,
    },
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.setRequisicoes();
  },
  methods: {
    carregarRequisicoes() {
      this.setRequisicoes();
    },
    async setRequisicoes() {
      await this.requisicaoExameService
        .getRequisicoesAbertas(this.petCodigo, this.unidadeId)
        .then(async (response) => {
          const { data } = await response.json();
          if (!data.length) {
            this.requisicoes = [];
            return;
          }
          this.requisicoes = data.map((item) => {
            return {
              formularioId: parseInt(item.formulario),
              filledAnamnese: parseInt(item.status_anamnese),
              requisicaoId: item.requisicao_procedimento_id,
              procedimentoDescricao: item.procedimento_descricao,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
              showModal: false,
            };
          });
        })
        .then(() => {
          console.log(this.requisicoes);
        })
        .catch((e) => {
          console.error({ e });
          this.requisicoes = [];
        });

      this.requisicaoExameService
        .getRequisicoesMedicacoesAbertas(this.petCodigo, this.unidadeId)
        .then(async (response) => {
          const { data } = await response.json();
          data.map((item) => {
            this.requisicoes.push({
              formularioId: parseInt(item.formulario),
              filledAnamnese: parseInt(item.status_anamnese),
              requisicaoId: item.requisicao_procedimento_id,
              procedimentoDescricao: item.procedimento_descricao,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
              showModal: false,
            });
          });
        })
        .then(() => console.log(this.requisicoes));
    },
    async marcarExecutado(requisicao) {
      this.loadingRequisicaoId = requisicao.requisicaoId;
      await RequisicaoExameService.build().marcarExecutado(
        ~~requisicao.requisicaoId
      );
      await this.setRequisicoes();
      this.loadingRequisicaoId = 0;
    },
  },
};
</script>
